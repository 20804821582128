import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { BASE_PATH } from 'config';
import GuestGuard from 'utils/route-guard/GuestGuard';
import Register from 'views/auth/register';
import Login from 'views/auth/Login';
import ForgotPassword from 'views/auth/ForgotPassword';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <Navigate to="/home" />
        </AuthGuard>
      )
    },
    {
      path: '/register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: '/login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: '/forgot-password',
      element: (
        <GuestGuard>
          <ForgotPassword />
        </GuestGuard>
      ),
    },
    MainRoutes,
  ]);
}
