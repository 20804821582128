import { useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, GridPagination } from '@mui/x-data-grid';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Grid } from '@mui/material';
import { getDuration } from './modules/getDuration';

const DataTable = ({ data, editItem = null, againItem = null, localeFormat, exportButton = null }) => {
  const columns = [
    { field: 'employee_name', headerName: localeFormat('Employee'), width: 140 },
    {
      field: 'total_time',
      headerName: localeFormat('Time spent'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        getDuration(params.row.total_time),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        slots={
          exportButton !== null ? { toolbar: CustomToolbar } : ''
        }
        pageSizeOptions={[10, 25, 100]}
      // checkboxSelection
      />
    </div>
  );
}

export default DataTable;