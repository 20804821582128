import React, { useState, useEffect } from "react";
import { Grid, TextField, Box, Autocomplete } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useIntl } from 'react-intl'; // Import the useIntl hook
import dayjs from 'dayjs';
import en from 'date-fns/locale/en-GB';
import et from 'date-fns/locale/et';
import { useLocale } from './contexts/LocaleContext';
import Button from '@mui/material/Button';

const DateRangePicker = ({ data, clients, setFilteredRows }) => {
  const [client, setClient] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const intl = useIntl();
  const { locale } = useLocale();

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // If start date is set and end date is before start, reset the end date
    if (endDate && date && date > endDate) {
      setEndDate(null);
    }
  };

  const handleClientChange = (event, value) => {
    setClient(value);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  function localeFormat(id) {
    return intl.formatMessage({ id });
  }

  useEffect(() => {
    // thisMonth();
  }, []);

  const thisMonth = () => {
    const firstDate = dayjs().startOf('month');
    setStartDate(firstDate);

    const lastDate = dayjs().endOf('month');
    setEndDate(lastDate);
  }

  const prevMonth = () => {
    const firstDate = dayjs().subtract(1, 'month').startOf('month');
    setStartDate(firstDate);

    const lastDate = dayjs().subtract(1, 'month').endOf('month');
    setEndDate(lastDate);
  }

  const handleApplyFilter = () => {
    const filteredRows = filterRows(data, client, startDate, endDate);
    console.log(filteredRows);
    setFilteredRows(filteredRows);
  };

  const handleCancelFilter = () => {
    setFilteredRows(data);
    setClient(null);
    setStartDate(null);
    setEndDate(null);
  };


  const filterRows = (data, clientIdFilter, startDateFilter, endDateFilter) => {
    console.log(clientIdFilter);
    return data.filter((row) => {
      const matchesClientId = clientIdFilter && row.client_id?.includes(clientIdFilter.id) ? true : false;
      const matchesStartDate = startDateFilter && new Date(row.date) >= new Date(startDateFilter) ? true : false;
      const matchesEndDate = endDateFilter && new Date(row.date) <= new Date(endDateFilter) ? true : false;
      console.log(matchesClientId, matchesStartDate, matchesEndDate);
      return matchesClientId && matchesStartDate && matchesEndDate;
    });
  };

  // Update filtered rows whenever filter criteria change
  const handleFilterChange = () => {
    const newFilteredRows = filterRows(data, client, startDate, endDate);
    setFilteredRows(newFilteredRows);
  };

  useEffect(() => {
    if (client || (startDate && endDate)) {
      handleFilterChange();
      console.log('Filtering data');
    }
  }, [client, startDate, endDate]);



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <Grid container spacing={1}>
        <Grid item md={2}>
          <Autocomplete
            id="clients"
            name="client"
            onChange={handleClientChange}
            value={client}
            //for testing duplicate client names
            // options={clients.map((option) => option.client_name + ' (' + option.id + ')' || "")}
            options={clients}
            getOptionLabel={(option) => option.client_name}
            renderInput={(params) => <TextField {...params} label={localeFormat("Choose or type a client")} />}
          />
        </Grid>
        <Grid item md={2}>
          <DatePicker
            label={localeFormat("Start Date")}
            format={localeFormat('dateFormat')}
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item md={2}>
          <DatePicker
            label={localeFormat("End Date")}
            format={localeFormat('dateFormat')}
            value={endDate}
            onChange={handleEndDateChange}
            minDate={startDate || undefined} // Prevent selecting end date before start date
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item md={1}>
          <Button variant="contained" onClick={handleApplyFilter}>
            {localeFormat("Apply Dates")}
          </Button>
        </Grid>
        <Grid item md={1}>
          <Button variant="outlined" onClick={thisMonth} sx={{ height: '100%', width: '100%' }}>
            {localeFormat("This month")}
          </Button>
        </Grid>
        <Grid item md={1}>
          <Button variant="outlined" onClick={prevMonth}>
            {localeFormat("Previous month")}
          </Button>
        </Grid>
        <Grid item md={1}>
          <Button variant="outlined" onClick={handleCancelFilter}>
            {localeFormat("Clear Filters")}
          </Button>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
