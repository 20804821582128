import { useEffect, useState } from 'react';
import { Grid, TextareaAutosize, Button, Typography, TextField, Autocomplete, FormHelperText } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import useCustomTranslation from 'hooks/useCustomTranslation';
import List from 'components/List';
import AlertDialog from 'AlertDialog';
import FormDialog from 'components/FormDialog';

function Admin() {
  const { getToken } = useAuth();
  const [clients, setClients] = useState([])

  const [newClient, setnewClient] = useState(null);
  const [newClientsMerged, setNewClientsMerged] = useState([]);
  const [removedClient, setRemovedClient] = useState(null);

  const [loading, setLoading] = useState(false);
  const { t } = useCustomTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');

  const [formOpen, setFormOpen] = useState(false);
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const [error, setError] = useState("");

  async function fetchClients() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/clients`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setClients(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function addClients() {
    console.log(newClient)
    //if newClient is empty
    if (!newClient) {
      return;
    }

    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_URL}/api/clients`;
      const token = await getToken();

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({ client_name: newClient }),
      });

      if (response.ok) {
        setnewClient(''); // Clear input on success
        fetchClients(); // Refresh clients list
        setError();
      } else {
        console.error('Failed to add client');
        setError(t('Failed to add client.'));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function confirmClientRemove() {
    setDialogContent(t('Are you sure you want to remove this client?'));
    setDialogTitle(t('Remove Client'));
    setDialogOpen(true);
  }

  const handleConfirm = async () => {
    removeClient()
  }

  const mergeClients = async () => {
    console.log(newClientsMerged)
    setFormOpen(true);
    setFormError('');
    setFormSuccess('');
  }

  const handleMerge = async (newClientName) => {
    // Perform merging logic here (update state or API call)
    console.log("Merging clients:", newClientsMerged, "into", newClientName);

    // Reset selection after merging
    // setSelectedClients([]);


    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_URL}/api/clients`;
      const token = await getToken();

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'PUT',
        headers,
        body: JSON.stringify({
          newClientsMerged: newClientsMerged,
          newName: newClientName
        }),
      });

      if (response.ok) {
        setnewClient(''); // Clear input on success
        fetchClients(); // Refresh clients list

        setDialogContent('');
        setDialogTitle('');

        setFormSuccess('Clients merged successfully');
        //add sleep 2s and then call setFormOpen(false)
        setTimeout(() => {
          setFormOpen(false);
          setNewClientsMerged([]);
          setnewClient('');
          setFormError('');
          setFormSuccess('');
        }, 2000);
      } else {
        console.error('Failed to add clients');
        setFormError('Failed to merge clients.');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const removeClient = async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_URL}/api/clients/` + removedClient?.id;
      const token = await getToken();

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'DELETE',
        headers,
        body: JSON.stringify({ textInput: newClient }),
      });

      if (response.ok) {
        setRemovedClient(''); // Clear input on success
        //TODO: returns undefined after complete
        fetchClients(); // Refresh clients list

        setDialogContent('');
        setDialogTitle('');

      } else {
        console.error('Failed to remove clients');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleNewClientChange = (value) => {
    console.log(value);
    setnewClient(value);
  };
  const handleMergedClients = (value) => {
    console.log(value);
    setNewClientsMerged(value);
  };
  const handleRemovedClient = (value) => {
    console.log(value);
    setRemovedClient(value);
  };

  useEffect(() => {
    fetchClients();
  }, []);


  return (
    <div>
      <Grid container spacing={2} mt={5}>
        <Grid item xs={12} md={8} sx={{ margin: 'auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">{t('Add New Clients')}</Typography>
              <Autocomplete
                id="add-client"
                freeSolo
                value={newClient}
                onChange={(event, newValue) => handleNewClientChange(newValue)}
                onInputChange={(event, newValue) => handleNewClientChange(newValue)}
                options={clients}
                getOptionLabel={(option) => {
                  // Handle both cases: option is an object or a string
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.client_name || '';
                }}
                renderInput={(params) => <TextField {...params} label={t("Type a client name")} />}
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
              <Button
                variant="contained"
                color="primary"
                onClick={addClients}
                disabled={loading}
                style={{ marginTop: '10px' }}
              >
                {loading ? t('Adding...') : t('Add Client')}
              </Button>
              <Grid mt={5}>
                <Typography variant="h6">{t('Merge clients')}</Typography>
                <Autocomplete
                  id="merge-clients"
                  multiple
                  value={newClientsMerged}
                  onChange={(event, newValue) => handleMergedClients(newValue)}
                  // onInputChange={(event, newValue) => handleMergedClients(newValue)}
                  options={clients}
                  getOptionLabel={(option) => option.client_name}
                  // options={clients.map((option) => option.client_name + ' (' + option.id + ')')}
                  renderInput={(params) => <TextField {...params} label={t("Type a client name")} />}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={mergeClients}
                  disabled={loading}
                  style={{ marginTop: '10px' }}
                >
                  {t('Merge clients')}
                </Button>
              </Grid>
              <Grid mt={5}>
                <Typography variant="h6">{t('Remove a client')}</Typography>
                <Autocomplete
                  id="remove-client"
                  value={removedClient}
                  onChange={(event, newValue) => handleRemovedClient(newValue)}
                  options={clients}
                  getOptionLabel={(option) => option.client_name}
                  renderInput={(params) => <TextField {...params} label={t("Type a client name")} />}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={confirmClientRemove}
                  disabled={loading}
                  style={{ marginTop: '10px' }}
                >
                  {t('Remove Client')}
                </Button>
              </Grid>
            </Grid>

            {/* Right side: Display existing clients */}
            <Grid item xs={12} md={6} pb={30}>
              <Typography variant="h6">{t('All Clients')}</Typography>
              <List items={clients} name="client_name" />

            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <AlertDialog
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleConfirm={handleConfirm}
        title={dialogTitle}
        content={dialogContent}
        localeFormat={t}
      />
      <FormDialog
        formOpen={formOpen}
        setFormOpen={setFormOpen}
        clients={newClientsMerged}
        onConfirm={handleMerge}
        formError={formError}
        formSuccess={formSuccess}
      />
    </div>
  );
}

export default Admin;
