import * as React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';


function renderRow(items, name) {
  return ({ index, style }) => (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton>
        <ListItemText primary={items[index][name]} />
      </ListItemButton>
    </ListItem>
  );
}

export default function VirtualizedList({ items, name }) {
  return (
    <Box sx={{ width: '100%', height: 460, maxWidth: 360, bgcolor: 'background.paper' }}>
      <FixedSizeList
        height={460}
        width={360}
        itemSize={46}
        itemCount={items.length}
        overscanCount={5}
      >
        {renderRow(items, name)}
      </FixedSizeList>
    </Box>
  );
}
