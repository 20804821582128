import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

export const getDuration = (seconds) => {
  dayjs.extend(duration);
  const dur = dayjs.duration(seconds, 'seconds');

  // Calculate total hours, minutes, and seconds
  const totalHours = Math.floor(seconds / 3600);
  const minutes = dur.minutes();
  const secondsRemaining = dur.seconds();

  // Format the duration as a time string
  const formattedTime = `${totalHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`;
  return formattedTime;
};