import { useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, GridPagination } from '@mui/x-data-grid';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Grid } from '@mui/material';
import { getDuration } from './modules/getDuration';

const DataTable = ({ data, editItem = null, againItem = null, localeFormat, exportButton = null, rowsPerPage }) => {
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'client_name', headerName: localeFormat('Client'), width: 150,
    },
    {
      field: 'total_time',
      headerName: localeFormat('Time spent'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        getDuration(params.row.total_time),
    },
  ];

  const calcTotal = () => {

    let totalTime = 0;
    for (let i = 0; i < data.length; i++) {
      // totalAmount += parseFloat(data[i].price) || 0; // Safely handle missing or invalid price
      totalTime += parseFloat(data[i].total_time) || 0; // Safely handle missing or invalid time
    }
    return { totalTime };
  }

  const CustomFooter = (props) => {
    let { totalTime } = calcTotal();

    if (totalTime > 0) {
      totalTime = getDuration(totalTime);
    }
    return (
      <>
        <div style={{ padding: '10px', backgroundColor: '#f9f9f9', border: '1px solid #ccc' }}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <strong>{localeFormat("Total Time")}:</strong> {totalTime}
            </Grid>
          </Grid>
        </div>
        <GridPagination {...props} />
      </>
    );
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: rowsPerPage ? rowsPerPage : 10 },
          },
        }}
        slots={
          exportButton !== null ? { toolbar: CustomToolbar, footer: (props) => <CustomFooter {...props} /> } : ''
        }
        pageSizeOptions={[10, 25, 100]}
      // checkboxSelection
      />
    </div>
  );
}

export default DataTable;