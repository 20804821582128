import React, { useState, useEffect } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { useLocale } from './contexts/LocaleContext';
import { useIntl } from 'react-intl';
import useAuth from './hooks/useAuth';
import Header from './header';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable2 from './DataTable2';
import DataTable3 from './DataTable3';
import { Grid, Autocomplete, TextField } from '@mui/material';
import DateRangePicker from 'DateRangePicker';

const Reports = () => {
  const { id } = useParams();
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [clients, setClients] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState()
  const { getToken } = useAuth();
  const [employees, setEmployees] = useState([]);

  function localeFormat(id) {
    return intl.formatMessage({ id });
  }



  async function fetchWorkedHours() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/monthly/jobs`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setData(data);
        setRowsPerPage(data?.length);
        console.log(data?.length)
        setFilteredRows(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchWorkedHoursByEmployees(uid) {
    try {
      let url = `${process.env.REACT_APP_URL}/api/monthly/jobs/employees`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        return data;
        // setData(data);
        // setFilteredRows(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchData = async () => {
    // Fetch data asynchronously
    let times = await fetchWorkedHoursByEmployees();

    setEmployees(times);
  };




  useEffect(() => {
    fetchWorkedHours();

    fetchData();
  }, []);

  return (
    <div>
      <div style={{ height: 'auto', width: '100%' }}>
        <Grid container>
          <Grid item xs={6}>
            <DataTable2 data={filteredRows} localeFormat={localeFormat} exportButton={true} rowsPerPage={rowsPerPage} />
          </Grid>
          <Grid item xs={6}>
            <DataTable3 data={employees} localeFormat={localeFormat} exportButton={true} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Reports;