import { useIntl } from 'react-intl';

const useCustomTranslation = () => {
  const intl = useIntl();

  const t = (id, values = {}) => {
    return intl.formatMessage({ id }, values);
  };

  return { t };
};

export default useCustomTranslation;