import { useEffect, useState } from 'react';
import { Grid, TextareaAutosize, Button, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import useCustomTranslation from 'hooks/useCustomTranslation';
import List from 'components/List';
function Admin() {
  const { getToken } = useAuth();
  const [clients, setClients] = useState([])
  const [activities, setActivities] = useState([]);
  const [newClientsText, setNewClientsText] = useState('');
  const [newActivitiesText, setNewActivitiesText] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useCustomTranslation();


  async function fetchActivities() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/activities`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const result = await fetch(url, {
        method: 'GET',
        headers
      });
      if (result.ok) {
        const data = await result.json();
        setActivities(data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function addActivities() {
    console.log(newActivitiesText)
    if (!newActivitiesText.trim()) return; // No action if input is empty

    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_URL}/api/activities`;
      const token = await getToken();

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({ textInput: newActivitiesText }),
      });

      if (response.ok) {
        setNewActivitiesText(''); // Clear input on success
        fetchActivities(); // Refresh activities list
      } else {
        console.error('Failed to add activities');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    fetchActivities();
  }, []);


  return (
    <div>
      <Grid container spacing={0}>

        <Grid item xs={12} md={3}>
          <Typography variant="h6">{t('Add New Activities')}</Typography>
          <TextareaAutosize
            minRows={6}
            placeholder="Enter activity names, one per line..."
            value={newActivitiesText}
            onChange={(e) => setNewActivitiesText(e.target.value)}
            style={{ width: '100%', padding: '10px', fontSize: '16px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={addActivities}
            disabled={loading}
            style={{ marginTop: '10px' }}
          >
            {loading ? t('Adding...') : t('Add Activity')}
          </Button>
        </Grid>

        {/* Right side: Display existing activities */}
        <Grid item xs={12} md={3} pb={30}>
          <Typography variant="h6">{t('All Activities')}</Typography>
          <List items={activities} name="activity" />
        </Grid>
      </Grid>
    </div>
  );
}

export default Admin;
