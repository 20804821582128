import React, { useState, useEffect } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { useLocale } from './contexts/LocaleContext';
import { useIntl } from 'react-intl';
import useAuth from './hooks/useAuth';
import Header from './header';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from './DataTable';
import { Grid, Autocomplete, TextField } from '@mui/material';
import DateRangePicker from 'DateRangePicker';

const Reports = () => {
  const { id } = useParams();
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [clients, setClients] = useState([])
  const { getToken } = useAuth();

  function localeFormat(id) {
    return intl.formatMessage({ id });
  }

  async function fetchJobs() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/jobs`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setData(data);
        setFilteredRows(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchClients() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/clients`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setClients(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchJobs();
    fetchClients();
  }, [id]);

  return (
    <div>
      <div style={{ height: 'auto', width: '100%' }}>
        <Grid container>
          <Grid item xs={12}>
            <DateRangePicker
              data={data}
              clients={clients}
              setFilteredRows={setFilteredRows}
            />
          </Grid>
          <Grid item xs={12}>
            <DataTable data={filteredRows} localeFormat={localeFormat} exportButton={true} footer="total" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Reports;