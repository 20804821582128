import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { Link } from 'react-router-dom';
export default function PositionedMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useCustomTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'inline-block', float: 'right', marginTop: '16px', verticalAlign: 'middle', lineHeight: '60px' }}>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {t('Admin')}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem>
          <Link style={{ color: '#000', textDecoration: 'none' }} to="/tasks">{t('Tasks')}</Link>
        </MenuItem>
        <MenuItem>
          <Link style={{ color: '#000', textDecoration: 'none' }} to="/employees">{t('Employees')}</Link>
        </MenuItem>
        <MenuItem>
          <Link style={{ color: '#000', textDecoration: 'none' }} to="/reports">{t('Reports')}</Link>
        </MenuItem>
        <MenuItem>
          <Link style={{ color: '#000', textDecoration: 'none' }} to="/monthly-reports">{t('Monthly report')}</Link>
        </MenuItem>
        <MenuItem>
          <Link style={{ color: '#000', textDecoration: 'none' }} to="/admin/clients">{t('Clients')}</Link>
        </MenuItem>
        <MenuItem>
          <Link style={{ color: '#000', textDecoration: 'none' }} to="/admin/activities">{t('Activities')}</Link>
        </MenuItem>
      </Menu>
    </Box>
  );
}
