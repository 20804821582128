import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormHelperText from '@mui/material/FormHelperText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Autocomplete from '@mui/material/Autocomplete';

export default function FormDialog({ formOpen, setFormOpen, clients, onConfirm, formError, formSuccess }) {
  const { t } = useCustomTranslation();
  const [newName, setNewName] = React.useState(null);

  const handleClose = () => {
    setFormOpen(false);
    setNewName(null);
  };

  const handleNewClientChange = (value) => {
    console.log(value);
    setNewName(value);
  };

  return (
    <React.Fragment>
      <Dialog
        open={formOpen}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            console.log(newName);
            onConfirm(newName);
            // handleClose();
          },
        }}
      >
        <DialogTitle>{t('Merge clients')}</DialogTitle>
        <DialogContent>
          {clients && clients.length > 0 && (
            <>
              <DialogContentText>
                <p>
                  {t('To merge these clients:')} <strong>{clients.map(client => client.client_name).join(', ')}</strong>;<br />
                  {t('select a name or type a new one:')}</p>
              </DialogContentText>
              <Autocomplete
                id="merge"
                name="merge"
                freeSolo
                value={newName}
                onChange={(event, newValue) => handleNewClientChange(newValue)}
                onInputChange={(event, newValue) => handleNewClientChange(newValue)}
                //TODO: for this would need a new function, because it will check by every keystroke,
                // so if it's a new value, we need to convert it to an object
                //rn we remove freesolo so you cant type a name... easier as well
                //NB! take the fix from getOptionLabel in Clients autocomplete
                getOptionLabel={(option) => {
                  // Handle both cases: option is an object or a string
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.client_name || '';
                }}
                options={clients}
                renderInput={(params) => <TextField {...params} label={t("Type a client name")} />}
              />
              {formError && <FormHelperText color="error">{formError}</FormHelperText>}
              {formSuccess && <FormHelperText color="">{formSuccess}</FormHelperText>}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button type="submit">{t('Confirm')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
